import React from 'react';

import Layout from "../layouts/site/layout";

function Mission() {
  return (
    <div className="App">
       <Layout>
           <div className="px-4 pt-7 pb-10">
                <h2 className="text-red-1000 font-bold text-base">Our mission</h2>
                <p className="mt-2">We aim to be regarded by our customers, competitors and employees as the world’s premier supplier in our targeted markets.</p>
                <p className="mt-2">We will dominate our targeted markets by offering unmatched levels of service.</p>
                <h3 className="text-center bold mt-2 mb-16">Forging Solutions Transforming Business</h3>
            </div>
       </Layout>
    </div>
  );
}

export default Mission;
